.networks {
  width: 39%;
  background-color: #6d7777;
  height: 100%;
  border-right: 1px solid #325c80;
  border-left: 1px solid #325c80;
  overflow: hidden;
}

.networks-header {
  width: 100%;
  height: 6.3%;
  line-height: 4vh;
  background: linear-gradient(#1d3649, #152935);
  border-top: 1px solid #325c80;
  display: flex;
  align-items: center;
}

.networks-header h2 {
  font-size: 2.25vh;
  padding-left: 1vh;
  font-weight: bold;
  color: #e0e0e0;
  margin-right: 10px;
}

.networks-header .show-hide {
  display: flex;
  align-items: center;
  padding-left: 1vh;
  padding-right: 1vh;
  font-size: 1.25vh;
  line-height: 1.5vh;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #aeaeae;
}

.show-hide b {
  font-weight: 600;
}

.choice {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.choice input {
  padding: 0.5vh;
  margin-left: 0.5vh;
  margin-right: 0.25vh;
  cursor: pointer;
}

.choice .icon {
  height: 2.5vh;
  width: 2.5vh;
  background: #325c80;
  border-radius: 50%;
  padding: 3px;
}

/* Graph */
.networks .networks-graph {
  background: radial-gradient(#152935, #010205);
  background-size: 100%;
  height: 93.7%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Slider */
.container-slider {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.container-slider .arrow {
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
}

.container-slider .left {
  left: 10px;
}
.container-slider .right {
  right: 10px;
}

.container-slider .wrapper {
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(0px);
}

.wrapper .slide {
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
}

.slide img {
  /* height: 90%; */
  width: 39vw;
  object-fit: cover;
}

/* Image */
/* img {
  width: 90%;
  height: 90%;
  padding: 10px;
  object-fit: cover;
} */

@media (max-width: 768px) {
  .networks {
    width: 100%;
    height: 40%;
    margin-top: 30px;
  }

  .networks-header {
    height: 10%;
  }
}
