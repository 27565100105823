.carouselWrapper {
  position: relative;
  overflow: hidden;
  margin: auto;
  box-sizing: border-box;
}

.imgWrap {
  position: absolute;
  top: 0;
  left: -26.5rem;
}
.imgWrap:hover {
  cursor: pointer;
}

li {
  list-style-type: none;
}

li img {
  object-fit: cover;
  object-position: 50% 15%;
}

.dn {
  display: none;
}

/* Mobile */
@media (max-width: 768px) {
  .imgWrap {
    left: -449px;
  }

  li img {
    width: 480px;
    height: 100%;
  }
}

/* Layar Lebar */
@media (min-width: 1440px) {
  .imgWrap {
    left: -25.9rem;
  }
}
