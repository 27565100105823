.wrapper-bar-chart {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 2px;
}

.wrapper-bar-chart .input-type {
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
  display: flex;
  flex-direction: column;
}

.input-type button {
  font-size: 8px;
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.input-type .index {
  background-color: #d74108;
}
.input-type .index:hover {
  background-color: #d36033;
}

.input-type .penduduk {
  background-color: rgb(189, 189, 189);
}
.input-type .penduduk:hover {
  background-color: rgb(245, 245, 245);
}

.input-type .parah {
  background-color: rgb(1, 80, 80);
}
.input-type .parah:hover {
  background-color: rgb(19, 111, 111);
}

/* Style Bar Chart */
text {
  fill: rgb(218, 218, 218) !important;
}
