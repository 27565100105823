.dana {
  height: 33.3%;
  width: 100%;
  background: radial-gradient(#264a60, #1d3649);
}

.dana-header {
  width: 100%;
  height: 4.25vh;
  line-height: 4vh;
  background: linear-gradient(#1d3649, #152935);
  border-top: 1px solid #325c80;
}

.dana-header h2 {
  font-size: 2.25vh;
  padding-left: 1vh;
  font-weight: bold;
  color: #e0e0e0;
}

.dana-content {
  /* background: radial-gradient(#325c80, #264a60); */
  height: 85%;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.wrapper-dana {
  text-align: right;
}

.dana-total {
  margin-bottom: 12px;
}

.dana-proyek {
  animation: animasidana 3s ease;
  opacity: 1;
}

.dana-total h3 {
  font-size: 1.8vh;
  font-weight: 600;
  color: rgb(243, 243, 243);
}

.dana-total span {
  font-size: 2.6vh;
  font-weight: 300;
  color: rgb(212, 212, 212);
}

.dana-proyek h3 {
  font-size: 1.6vh;
  font-weight: 400;
  color: rgb(252, 31, 31);
}

.dana-proyek span {
  font-size: 1.8vh;
  font-weight: 100;
  color: rgb(243, 243, 243);
}

/* animasi */
@keyframes animasidana {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
