.header {
  width: 100%;
  height: 7vh;
  background: linear-gradient(#1d3649, #152935);
  display: flex;
  align-items: center;
  padding: 7px;
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.headerLeft {
  flex: 6;
  /* background-color: teal; */
  display: flex;
  align-items: center;
}

.headerLeftLogo {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.logoTitle b {
  font-weight: 600;
  font-size: 32px;
  color: white;
}
.logoTitle span {
  font-size: 32px;
  font-weight: 100;
  color: #aeaeae;
}

.icon {
  font-size: 40px;
  margin-right: 10px;
  cursor: pointer;
  color: white;
}

.headerLeftLogo h1 {
  font-weight: normal;
  font-size: 24px;
}

.search {
  display: flex;
  align-items: center;
}

.search label {
  font-size: 14px;
  margin: 0 10px;
  white-space: nowrap;
}
.search label:hover {
  cursor: pointer;
  color: inherit;
}

.search .inputText {
  font-size: 16px;
  padding: 5px;
  border: none;
  height: 30px;
  width: 170px;
}

.search .inputTanggal {
  font-size: 16px;
  padding: 5px;
  border: none;
  height: 30px;
  width: 90px;
  animation: moveTanggal 0.5s ease;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search input:focus {
  outline: none;
}

.search button {
  height: 30px;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: #d74108;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
}

.search button:hover {
  background-color: #d45827;
}

/* Right */
.headerRight {
  flex: 2;
  /* background-color: gray; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerRight .icon {
  margin-right: 20px;
}

@keyframes moveTanggal {
  from {
    width: 0;
  }
  to {
    width: 90px;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .headerLeftLogo {
    margin-right: 10px;
  }
  .logoTitle b {
    display: none;
  }

  .logoTitle span {
    font-size: 18px;
  }

  .headerRight .icon {
    display: none;
  }

  .search label {
    display: none;
  }

  .search .inputText {
    font-size: 14px;
    width: 120px;
  }
}
