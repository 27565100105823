.home {
  background: linear-gradient(#1d3649, #152935);
  width: 100vw;
  height: 100vh;
  position: relative;
}

.main-content {
  display: flex;
  width: 100%;
  height: 85vh;
}

.right-content {
  width: 25%;
  height: 100%;
  position: relative;
  background-color: #fbffff;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

/* Mobile */
@media (max-width: 768px) {
  .home {
    width: 100%;
  }

  .main-content {
    flex-wrap: wrap;
    margin-top: 2px;
  }

  .right-content {
    width: 100%;
  }
}
