.detail {
  background: linear-gradient(#1d3649, #152935);
  border-left: 5px solid #00a6a0;
  border-top: 1px solid #325c80;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1px;
}

.detail:hover {
  cursor: pointer;
  background: linear-gradient(#11212c, #070f14);
}

.detail h3 {
  font-size: 1.75vh;
  font-family: "Merriweather", serif;
  line-height: 2.5vh;
  padding: 1vh 1.5vh;
  font-weight: bold;
  color: #c7c7c7;
}

.detail h3 span {
  color: tomato;
  font-style: italic;
  font-size: 10px;
  margin-left: 5px;
  font-variant: small-caps;
}

.detail-desc {
  margin-left: 10px;
  line-height: 2.5vh;
  font-size: 1.5vh;
  padding: 1vh 0vh 1vh 0vh;
  color: #c7c7c7;
}

.timeStamp,
.fullArticle span {
  font-size: 1.25vh;
  color: #959595;
}

.fullArticle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px 10px 0;
  color: #959595;
}

/* .fullArticle a:hover {
  color: tomato;
  font-weight: bold;
} */
