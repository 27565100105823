* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #808080;
  font-family: "Open Sans", sans-serif;
  font-size: 1vh;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* Untuk Map */
.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.navigation-control {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}
