.locations {
  height: 33.3%;
  width: 100%;
  background: radial-gradient(#264a60, #1d3649);
}

.location-header {
  width: 100%;
  height: 4.25vh;
  line-height: 4vh;
  background: linear-gradient(#1d3649, #152935);
  border-top: 1px solid #325c80;
  display: flex;
  align-items: center;
}

.location-header h2 {
  font-size: 2.25vh;
  padding-left: 1vh;
  font-weight: bold;
  color: #e0e0e0;
}

.location-images {
  width: 100%;
  height: 85%;
  /* height: 95.75vh; */
  position: relative;
}

.imageContainer {
  position: relative;
}

.imageContainer img {
  /* position: absolute; */
  width: 23vw;
  height: 24.5vh;
  object-fit: cover;
  object-position: 50% 14%;
}

.imageContainer img:hover {
  cursor: pointer;
}

/* mapbox */
.locations .mapboxgl-map {
  height: 91% !important;
}

/* lightbox-wrapper */
.lightbox-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(29, 54, 73, 0.9), rgba(21, 41, 53, 0.9));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lightbox-wrapper .iconClose {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: white;
  color: tomato;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  position: absolute;
  top: 25px;
  right: 50vw;
  cursor: pointer;
  opacity: 1;
  animation: icon 3s ease;
}
.lightbox-wrapper img {
  margin-bottom: 10px;
  height: 85%;
  /* width: 80vw; */
  /* object-fit: cover; */
  animation: imgLight 1s ease;
}

.lightbox-wrapper h3 {
  font-size: 18px;
  text-align: center;
  color: rgb(231, 231, 231);
}

@keyframes imgLight {
  from {
    height: 0;
  }

  to {
    height: 85%;
  }
}
@keyframes icon {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .location-images img {
    width: 100%;
    height: 156px;
  }

  .lightbox-wrapper .iconClose {
    top: 120px;
    right: 12px;
  }

  .lightbox-wrapper h3 {
    font-size: 11px;
  }
  .lightbox-wrapper img {
    height: 55%;
    width: 90%;
  }
}
